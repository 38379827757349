import { defineMessages } from 'react-intl';

const messages = defineMessages({
  login: {
    id: 'menu.login',
    defaultMessage: 'Login',
  },
  logoutInfo: {
    id: 'menu.logoutInfo',
    defaultMessage: 'Not {name}?',
  },
  logout: {
    id: 'menu.logoutLink',
    defaultMessage: 'Logout',
  },
  signUp: {
    id: 'menu.createLink',
    defaultMessage: 'Register now',
  },
  signUpInfo: {
    id: 'menu.createInfo',
    defaultMessage: 'New here?',
  },
  myAccount: {
    id: 'menu.myaccount',
    defaultMessage: 'My account',
  },
  myKompleteOffers: {
    id: 'menu.upgrades',
    defaultMessage: 'My KOMPLETE Offers',
  },
  myProductSerials: {
    id: 'menu.registeredproducts',
    defaultMessage: 'My products and serials',
  },
  accountSettings: {
    id: 'menu.accountsettings',
    defaultMessage: 'Name, address, email, password',
  },
  orders: {
    id: 'menu.orderhistory',
    defaultMessage: 'Order history',
  },
  downloads: {
    id: 'menu.updatesdrivers',
    defaultMessage: 'Updates and drivers',
  },
  subscription: {
    id: 'menu.subscription',
    defaultMessage: 'Subscription',
  },
});

export default messages;
