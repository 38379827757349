import { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';

import { getTokens } from '../helpers/getTokens';
import { legacyEmitLoginState } from '../helpers/legacyEmitLoginState';
import { useUserTracking } from '../helpers/tracking';
import { useOnClickOutside } from '../helpers/useOnClickOutside';
import { accountButtonStyles } from '../styles';
import AccountMenu, { type StateContext } from './AccountMenu';

const AccountButton = () => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [hasPopover, setHasPopover] = useState(false);
  const context: StateContext = {
    isLoggedIn,
    hasPopover,
    handlers: { setIsLoggedIn, setHasPopover },
  };

  useEffect(() => {
    getTokens().then((isLoggedIn) => setIsLoggedIn(isLoggedIn));
  }, []);

  useEffect(() => {
    // @TODO: Remove once Typo3 is dead. Needed for website-integration's Account.js
    if (typeof isLoggedIn === 'boolean') {
      legacyEmitLoginState(isLoggedIn);
    }
  }, [isLoggedIn]);

  useOnClickOutside(ref, () => setHasPopover(false));
  useUserTracking({ isLoggedIn });

  return (
    <span ref={ref}>
      <button
        data-testid={isLoggedIn ? 'account-icon-auth' : 'account-icon'}
        css={accountButtonStyles(Boolean(isLoggedIn))}
        onClick={() => setHasPopover(!hasPopover)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
        >
          <path
            css={css`
              transition: fill 0.15s ease-in-out;
            `}
            d="M11.3 9.75c-1.87-.41-3.58-.81-2.77-2.36C11.05 2.6 9.18 0 6.5 0S2 2.68 4.47 7.39c.9 1.61-.89 1.95-2.76 2.36S.12 11 0 13h13c-.14-2 .17-2.76-1.7-3.25z"
          />
        </svg>
      </button>
      {hasPopover && <AccountMenu context={context} />}
    </span>
  );
};

export default AccountButton;
