const knownLanguages = ['de', 'en', 'ja', 'es', 'fr', 'zh'];

const getLocaleFromPathname = () =>
  window.location.pathname.replace(/^\//, '').split('/')[0];

const getLanguage = (mapLang?: Record<string, string>) => {
  if (typeof window === 'undefined') return 'en';

  let pageLanguage = getLocaleFromPathname();

  if (pageLanguage === 'jp') {
    pageLanguage = 'ja';
  }

  if (pageLanguage && knownLanguages.indexOf(pageLanguage) !== -1) {
    return mapLang && mapLang[pageLanguage] !== undefined
      ? mapLang[pageLanguage]
      : pageLanguage;
  }

  const browserLanguage =
    window.navigator.language && window.navigator.language.slice(0, 2);

  if (browserLanguage && knownLanguages.indexOf(browserLanguage) !== -1) {
    return mapLang && mapLang[browserLanguage] !== undefined
      ? mapLang[browserLanguage]
      : browserLanguage;
  }

  return 'en';
};

export { getLanguage, getLocaleFromPathname };
