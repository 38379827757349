import { createAuth0Client } from '@auth0/auth0-spa-js';
import cookies from 'js-cookie';
import jwtDecode, { type JwtPayload } from 'jwt-decode';

import {
  APP_BASE_URL,
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  COOKIE_DOMAIN,
} from '../config';
import { ACCESS_TOKEN_COOKIE } from '../constants';

export { getTokens };

function getTokens() {
  const accessToken = cookies.get(ACCESS_TOKEN_COOKIE);
  const auth0Client = createAuth0Client({
    domain: AUTH0_DOMAIN,
    clientId: AUTH0_CLIENT_ID,
    authorizationParams: {
      audience: AUTH0_AUDIENCE,
      redirect_uri: `${APP_BASE_URL}/auth/callback`,
    },
  });

  if (accessToken) {
    return Promise.resolve(true);
  }

  return auth0Client
    .then((client) =>
      client.getTokenSilently({
        authorizationParams: { audience: AUTH0_AUDIENCE, timeoutInSeconds: 5 },
      }),
    )
    .then((token) => {
      cookies.set(ACCESS_TOKEN_COOKIE, token, {
        expires: getTokenExpiration(token),
        domain: COOKIE_DOMAIN,
      });

      return Promise.resolve(true);
    })
    .catch(() => {
      return Promise.resolve(false);
    });
}

function getTokenExpiration(token: string) {
  const decodedToken = jwtDecode<JwtPayload>(token);
  const TIME_IN_MS = 1000;

  if (decodedToken?.exp) {
    return decodedToken.exp * TIME_IN_MS;
  }

  return 0;
}
