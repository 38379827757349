import { Children } from 'react';
import { IntlProvider } from 'react-intl';

import { useTranslationsQuery, type TranslationsQuery } from '../graphql';

const DEFAULT_LOCALE = 'en';

type LanguageProviderProps = {
  locale?: string;
  keys?: string[] | null;
  children?: React.ReactNode;
};

function LanguageProvider({
  locale = DEFAULT_LOCALE,
  keys = null,
  children,
}: LanguageProviderProps) {
  const { data: { translations } = {}, loading } = useTranslationsQuery({
    variables: { locale, keys },
    ssr: false,
  });

  if (loading || !translations) {
    return null;
  }

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={createTranslationMap(translations)}
      textComponent="span"
    >
      {Children.only(children)}
    </IntlProvider>
  );
}

const createTranslationMap = (
  translations: TranslationsQuery['translations'],
) => {
  const messages: Record<string, string> = {};

  if (translations) {
    translations.forEach((translation) => {
      if (!translation) return;

      const { key, value } = translation;

      if (key && value) {
        messages[key] = value;
      }
    });
  }
  return messages;
};

export default LanguageProvider;
