import qs from 'query-string';

import { APP_BASE_URL } from '../config';
import { AUTH_LOGGED_IN_PARAMETER } from '../constants';
import { getLanguage } from './localization';

export const isPageProtected = () => {
  const protectedUrlPatterns = ['/shop/checkout'];
  const patternIdx = protectedUrlPatterns.findIndex((urlPattern) =>
    window.location.href.includes(urlPattern),
  );
  return patternIdx >= 0;
};

const AUTH_ROUTES = {
  LOGIN: '/auth/login/',
  SIGNUP: '/auth/signup/',
  LOGOUT: '/auth/logout/',
};

export const getLoginUrl = (returnTo?: string) =>
  qs.stringifyUrl({
    url: AUTH_ROUTES.LOGIN,
    query: {
      returnTo:
        returnTo ||
        qs.stringifyUrl({
          url: window.location.href,
          query: {
            [AUTH_LOGGED_IN_PARAMETER]: true,
          },
        }),
      lang: getLanguage(),
    },
  });

export const getSignUpUrl = (returnTo?: string) =>
  qs.stringifyUrl({
    url: AUTH_ROUTES.SIGNUP,
    query: {
      returnTo:
        returnTo ||
        qs.stringifyUrl({
          url: window.location.href,
          query: {
            [AUTH_LOGGED_IN_PARAMETER]: true,
          },
        }),
      lang: getLanguage(),
    },
  });

export const getLogOutUrl = () =>
  qs.stringifyUrl({
    url: AUTH_ROUTES.LOGOUT,
    query: {
      returnTo: isPageProtected()
        ? `${APP_BASE_URL}/${getLanguage()}`
        : window.location.href,
    },
  });
