const STORE =
  typeof window === 'undefined' ? process.env : window.APP_ENV_SETTINGS || {};
const NODE_ENV = STORE.NODE_ENV || 'development';
const COOKIE_DOMAIN = STORE.COOKIE_DOMAIN || '.test.ni.de';
const { APP_BASE_URL, AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE } = STORE;

export {
  NODE_ENV,
  APP_BASE_URL,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_AUDIENCE,
  COOKIE_DOMAIN,
};
