export { persistedDataLayer, pushDataEvent };

type DataEvent = Record<string, unknown>;

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}

function pushDataEvent(
  data: DataEvent,
  { persist }: { persist: boolean } = { persist: false },
) {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push(data);
    if (persist) persistedDataLayer.setEvent(data);
  }
}

/*
 * A dataLayer persisted in browser session,
 * for data variables that must be consistent across pages.
 */
const DATALAYER_KEY = 'shop/datalayer';

function restore() {
  return new Promise((resolve) => {
    const sessionDataLayer = getEvents();

    if (!sessionDataLayer) {
      resolve(false);
      return;
    }

    sessionDataLayer.forEach((sessionEvent) => {
      const isDuplicate = window.dataLayer.some(
        (event) => JSON.stringify(event) === JSON.stringify(sessionEvent),
      );
      if (!isDuplicate) pushDataEvent(sessionEvent);
    });

    resolve(true);
  });
}

function flush() {
  sessionStorage.removeItem(DATALAYER_KEY);
}

function getEvents(): Record<string, unknown>[] {
  const sessionDataLayer = sessionStorage.getItem(DATALAYER_KEY);
  return sessionDataLayer ? JSON.parse(sessionDataLayer) : null;
}

function setEvent(event: DataEvent) {
  const sessionDataLayer = getEvents();

  sessionStorage.setItem(
    DATALAYER_KEY,
    JSON.stringify(sessionDataLayer ? [...sessionDataLayer, event] : [event]),
  );
}

const persistedDataLayer = {
  getEvents,
  setEvent,
  restore,
  flush,
};
