import { css } from '@emotion/react';

import { theme } from './constants';

export {
  wrapperStyles,
  headerStyles,
  linkStyles,
  loginButtonStyles,
  nameStyles,
  userEmailStyles,
  logOutInfoStyles,
  globalStyles,
  accountButtonStyles,
};

const wrapperStyles = css`
  position: absolute;
  z-index: 1010;
  text-align: left;
  background-color: ${theme.colors.white};
  white-space: normal;
  width: 100%;
  max-width: 376px;
  border: 1px solid #ddd;
  border-right: 0;
  top: 60px;
  right: 0;
  padding: 15px 25px;
  line-height: 1.15;

  ul {
    margin: 0;
    padding: 0;
    list-style-position: outside;
    list-style-image: none;
  }
`;

const headerStyles = css`
  :after {
    content: '';
    border-bottom: 1px solid #d9d9d9;
    display: block;
    margin: 15px 0;
  }
`;

const loginButtonStyles = css`
  display: block;
  text-align: center;
  cursor: pointer;
  margin: 5px 0 10px;
  padding: 0.5rem 0.875rem;
  line-height: 1.5;
  font-weight: 500;
  background-color: ${theme.colors.primaryBlue};
  color: ${theme.colors.white};

  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
    transition: all 0.4s ease;
    color: ${theme.colors.white};
  }
`;

const nameStyles = css`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 8px;
`;

const userEmailStyles = css`
  font-size: 14px;
`;

const logOutInfoStyles = css`
  width: 100%;
  text-align: left;
  :before {
    content: '';
    border-top: 1px solid #d9d9d9;
    display: block;
    margin: 15px 0;
  }
`;

const globalStyles = `
  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: 
      url('/assets/fonts/Graphik-Regular-Web.woff2') format('woff2'),
      url('/assets/fonts/Graphik-Regular-Web.woff') format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: 
      url('/assets/fonts/Graphik-RegularItalic-Web.woff2') format('woff2'),
      url('/assets/fonts/Graphik-RegularItalic-Web.woff') format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: 
      url('/assets/fonts/Graphik-Medium-Web.woff2') format('woff2'),
      url('/assets/fonts/Graphik-Medium-Web.woff') format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: 
      url('/assets/fonts/Graphik-MediumItalic-Web.woff2') format('woff2'),
      url('/assets/fonts/Graphik-MediumItalic-Web.woff') format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: 
      url('/assets/fonts/Graphik-Semibold-Web.woff2') format('woff2'),
      url('/assets/fonts/Graphik-Semibold-Web.woff') format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: 
      url('/assets/fonts/Graphik-SemiboldItalic-Web.woff2') format('woff2'),
      url('/assets/fonts/Graphik-SemiboldItalic-Web.woff') format('woff');
  }

  body {
    font-family: 'Graphik', Helvetica, Arial, sans-serif;
    font-size: 18px;
    color: ${theme.colors.black};
  }
`;

const linkStyles = css`
  color: ${theme.colors.linkColor};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.linkHoverColor};
  }
`;

const accountButtonStyles = (isLoggedIn: boolean) => css`
  outline: none;
  position: relative;
  display: inline-block;
  background: none;
  border: none;
  padding: 10px 15px;
  position: relative;
  line-height: 1.5;
  svg {
    fill: ${isLoggedIn ? theme.colors.primaryBlue : theme.colors.black};
  }
  :disabled svg {
    fill: ${theme.colors.linkNaviGrey};
  }
  :not(:disabled) {
    &:hover,
    &:active {
      outline: none;
      cursor: pointer;
      svg {
        fill: ${isLoggedIn
          ? theme.colors.primaryBlueHover
          : theme.colors.primaryBlue};
      }
    }
  }
`;
