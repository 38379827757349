import { createRoot } from 'react-dom/client';

import App from './app';

/*
  To be used standalone by non-React apps.
*/
const rootElement = document.getElementById('react-root-login');

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
}
