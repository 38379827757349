// @TODO: Remove once Typo3 is dead. Needed for website-integration's Account.js
import Cookies from 'js-cookie';
import type { Emitter } from 'mitt';

import { COOKIE_DOMAIN } from '../config';

export { legacyEmitLoginState };

declare global {
  interface Window {
    emitter: Emitter;
    loginStateResolved: boolean;
  }
}

function legacyEmitLoginState(isLoggedIn: boolean) {
  window.loginStateResolved = true;
  window.emitter.emit('login-state', isLoggedIn);

  if (isLoggedIn) {
    Cookies.set('lg', '1', { domain: COOKIE_DOMAIN });
  } else {
    Cookies.remove('lg');
  }
}
