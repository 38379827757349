import Cookies from 'js-cookie';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';

import { ACCESS_TOKEN_COOKIE } from '../constants';

export default () => {
  const httpLink = new HttpLink({
    uri: '/graphql',
    credentials: 'same-origin',
  });

  const authLink = new ApolloLink((operation, forward) => {
    const context = operation.getContext();
    const token = Cookies.get(ACCESS_TOKEN_COOKIE);

    operation.setContext({
      headers: {
        ...context.headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    });

    return forward(operation);
  });

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([authLink, httpLink]),
  });

  return apolloClient;
};
