export { ACCESS_TOKEN_COOKIE, theme, AUTH_LOGGED_IN_PARAMETER };

const AUTH_LOGGED_IN_PARAMETER = 'loggedIn';

const ACCESS_TOKEN_COOKIE = 'access-token';

const theme = {
  colors: {
    primaryBlue: '#3e8cbb',
    primaryBlueHover: '#48a1d9',
    linkColor: '#5d86a0', // brand primary
    linkHoverColor: '#5D86A0',
    linkNaviGrey: '#555',
    black: '#222',
    white: '#fff',
  },
};
