import { memo, type ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Global } from '@emotion/react';

import AccountButton from './components/AccountButton';
import LanguageProvider from './components/LanguageProvider';
import createApolloClient from './helpers/createApolloClient';
import { getLanguage } from './helpers/localization';
import { globalStyles } from './styles';

/*
  To be used by React apps.
*/
const apolloClient = createApolloClient();

const AppWrapper = ({ children }: { children: ReactNode }) => (
  <ApolloProvider client={apolloClient}>
    <Global styles={globalStyles} />
    <LanguageProvider locale={getLanguage()}>{children}</LanguageProvider>
  </ApolloProvider>
);

const App = memo(() => {
  return (
    <AppWrapper>
      <AccountButton />
    </AppWrapper>
  );
});

App.displayName = 'App';

export default App;
