import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import jwtDecode, { type JwtPayload } from 'jwt-decode';

import { ACCESS_TOKEN_COOKIE, AUTH_LOGGED_IN_PARAMETER } from '../constants';
import { useUserTrackingLazyQuery } from '../graphql';
import { persistedDataLayer, pushDataEvent } from './dataLayer';

export { useUserTracking };

type DataEvent = Record<string, unknown>;

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: DataEvent[];
  }
}

function useUserTracking({ isLoggedIn }: { isLoggedIn: boolean | null }) {
  const [getUser, { data: userData }] = useUserTrackingLazyQuery();
  const [isPersisted, setIsPersisted] = useState(false);
  const user =
    userData?.userV2?.__typename === 'AuthenticatedUser'
      ? userData.userV2
      : null;

  useEffect(() => {
    /*
     * The Native ID must be available on all pages.
     *
     * To prevent repeated requests for the ID on every page refresh,
     * we first attempt to restore the ID from the data layer persisted in session.
     * This ensures a request is only made again upon a fresh user login.
     */
    const { isFreshLogin, clearFreshLoginParam } = getFreshLoginParam();

    if (isFreshLogin()) {
      getUser();
      clearFreshLoginParam();
      persistedDataLayer.flush();
    } else if (isLoggedIn && !isPersisted) {
      persistedDataLayer.restore().then(() => setIsPersisted(true));
    }
  }, [getUser, isLoggedIn, setIsPersisted, isPersisted]);

  useEffect(() => {
    /*
     * Only called when the event cannot be pushed from the restored session.
     */
    if (!user) return;

    pushDataEvent(
      {
        event: 'analyticsEvent',
        eventCategory: 'Auth',
        eventAction: 'EcommerceStatus',
        eventLabel: user.ecommerce_status,
        ecommerceStatus: user.ecommerce_status,
      },
      { persist: true },
    );

    pushDataEvent(
      {
        event: 'analyticsEvent',
        eventCategory: 'Auth',
        eventAction: 'Identification',
        eventLabel: user.native_id,
        nativeId: user.native_id,
        authMethod: getAuthMethod(),
      },
      { persist: true },
    );
  }, [user]);
}

function getFreshLoginParam() {
  const url = new URL(window.location.href);
  return {
    isFreshLogin: () => url?.searchParams.has(AUTH_LOGGED_IN_PARAMETER),
    clearFreshLoginParam: () => {
      url.searchParams.delete(AUTH_LOGGED_IN_PARAMETER);
      window.history.replaceState(null, '', url.toString());
    },
  };
}

function getAuthMethod() {
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE);

  let authMethod = '';
  if (accessToken != null) {
    const tokenSub = jwtDecode<JwtPayload>(accessToken).sub;
    authMethod = tokenSub?.split('|')[0] ?? '';
  }
  return authMethod;
}
