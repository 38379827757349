import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/react';

import messages from '../app.messages';
import { APP_BASE_URL } from '../config';
import { theme } from '../constants';
import { useUserQuery, type AuthenticatedUser } from '../graphql';
import { getLoginUrl, getLogOutUrl, getSignUpUrl } from '../helpers/authUrls';
import { persistedDataLayer } from '../helpers/dataLayer';
import { getLocaleFromPathname } from '../helpers/localization';
import {
  headerStyles,
  linkStyles,
  loginButtonStyles,
  logOutInfoStyles,
  nameStyles,
  userEmailStyles,
  wrapperStyles,
} from '../styles';

export type StateContext = {
  isLoggedIn: boolean | null;
  hasPopover: boolean;
  handlers: {
    setIsLoggedIn: (value: boolean) => void;
    setHasPopover: (value: boolean) => void;
  };
};

const AccountMenu = ({ context }: { context: StateContext }) => {
  const { data, loading, error } = useUserQuery({
    skip: !context.isLoggedIn,
    ssr: false,
  });

  if (loading || error) {
    return null;
  }

  const isAuthenticated = data?.userV2?.__typename === 'AuthenticatedUser';

  if (!context.isLoggedIn || !isAuthenticated) {
    return (
      <div css={wrapperStyles} data-testid="account">
        <div css={headerStyles}>
          <a
            css={loginButtonStyles}
            id="account-menu-login-button"
            data-testid="account-login-button"
            href={getLoginUrl()}
          >
            <FormattedMessage {...messages.login} />
          </a>
          <FormattedMessage {...messages.signUpInfo} />{' '}
          <a
            css={linkStyles}
            data-testid="account-sign-up-button"
            href={getSignUpUrl()}
          >
            <FormattedMessage {...messages.signUp} />
          </a>
        </div>
        <MenuItems context={context} />
      </div>
    );
  }

  const {
    first_name: firstName,
    last_name: lastName,
    email,
  } = data.userV2 as Partial<AuthenticatedUser>;

  return (
    <div css={wrapperStyles} data-testid="account">
      <div css={headerStyles}>
        <div css={nameStyles} data-testid="account-name">
          {firstName} {lastName}
        </div>
        <div css={userEmailStyles} data-testid="account-email">
          {email}
        </div>
      </div>

      <MenuItems context={context} />

      <div css={logOutInfoStyles}>
        <FormattedMessage
          {...messages.logoutInfo}
          values={{ name: `${firstName} ${lastName}` }}
        />{' '}
        <a
          css={linkStyles}
          data-testid="account-logout-button"
          onClick={() => {
            persistedDataLayer.flush();
          }}
          href={getLogOutUrl()}
        >
          <FormattedMessage {...messages.logout} />
        </a>
      </div>
    </div>
  );
};

const MenuItems = ({ context }: { context: StateContext }) => {
  return (
    <ul className="account-snippet-menu">
      <MenuLink context={context} href="/account">
        <FormattedMessage {...messages.myAccount} />
      </MenuLink>
      <MenuLink context={context} href="/products/my-komplete-offers/">
        <FormattedMessage {...messages.myKompleteOffers} />
      </MenuLink>
      <MenuLink
        context={context}
        href="/my-account/my-products-serials-downloads/"
      >
        <FormattedMessage {...messages.myProductSerials} />
      </MenuLink>
      <MenuLink context={context} href="/my-account/account-settings/">
        <FormattedMessage {...messages.accountSettings} />
      </MenuLink>
      <MenuLink context={context} href="/account/orders/">
        <FormattedMessage {...messages.orders} />
      </MenuLink>
      <MenuLink context={context} href="/support/downloads/">
        <FormattedMessage {...messages.downloads} />
      </MenuLink>
      <MenuLink context={context} href="/account/subscription/">
        <FormattedMessage {...messages.subscription} />
      </MenuLink>
    </ul>
  );
};

const MenuLink = ({
  href,
  children,
  context,
}: {
  href: string;
  children: ReactNode;
  context: StateContext;
}) => {
  const url = `${APP_BASE_URL}/${getLocaleFromPathname()}${href}`;
  const {
    isLoggedIn,
    handlers: { setHasPopover },
  } = context;

  return (
    <li
      css={css`
        list-style: none;
      `}
    >
      <a
        href={isLoggedIn ? url : getLoginUrl(url)}
        onClick={() => setHasPopover(false)}
        css={css`
          display: block;
          padding: 4px;
          color: ${theme.colors.black};
          line-height: 1.6;
          transition: all 0.15s ease-in-out;

          &:hover,
          &:focus,
          &:active {
            text-decoration: none;
          }

          &:hover {
            background-color: ${theme.colors.primaryBlue};
            color: ${theme.colors.white};
          }
        `}
      >
        {children}
      </a>
    </li>
  );
};

export default AccountMenu;
